<template>
<div>
  <b-container fluid style="background-image: url('/img/commodities.jpg'); background-repeat: no-repeat; background-size: cover; height: 478px; margin: 0px;">
    <b-row>
      <b-col style="color: white;">
        <b-container>
          <b-row>
            <b-col>
              <h1 class="kstyle" style="font-size: 42px; font-weight: 500; margin-top: 200px;">Welcome to Commodities</h1>
              <p style="font-size: 18px;">Regulatory content curated by a network of specialists and supporting commodities sector.
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="mt-4" style="padding-bottom: 100px;">
    <b-row class="link-cards">
      <b-col lg="12">
        <h2 class="mb-4" style="font-size: 20px; font-weight: bold;">Get Started</h2>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'CommoditiesInitiatives' }" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-folder class="home-icon"></b-icon-folder></div>
            <div class="link-card-link p-3 m-0" style="text-align: center;">Browse initiatives</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'CommoditiesObligations', params: {id: 154 }}" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-grid-3x3 class="home-icon"></b-icon-grid-3x3></div>
            <div class="p-3 m-0" style="text-align: center;">Browse obligations</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'CommoditiesNews', params: {id: 154 }}" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-newspaper class="home-icon"></b-icon-newspaper></div>
            <div class="p-3 m-0" style="text-align: center;">Access latest news</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'CommoditiesInsights' }" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-binoculars class="home-icon"></b-icon-binoculars></div>
            <div class="p-3 m-0" style="text-align: center;">Explore insights</div>
          </div>
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-5">
        <h2 style="font-size: 20px; font-weight: bold;">About Commodities</h2>
        <b-container class="shadow p-3 bg-white">
          <b-row>
            <b-col class="text-center" lg="4" sm="12">
              <div>
                <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/jamesmaycock.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
              </div>
              <p class="mb-0"><strong>James Maycock</strong></p>
              <p class="mb-0"><small>Partner</small></p>
              <p class="mb-5"><small>KPMG in the UK</small></p>
              <div>
                <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/leenbroekhuizen.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
              </div>
              <p class="mb-0"><strong>Leen Broekhuizen</strong></p>
              <p class="mb-0"><small>Director</small></p>
              <p><small>KPMG in the UK</small></p>
            </b-col>
            <b-col lg="8" sm="12">
              <div>
                <p>The Commodities page on the KPMG Regulatory Horizon platform provides access to KPMG UK’s library of key regulatory initiatives and associated obligations related to commodities sector.</p>
                <p>Our KPMG UK specialists have created a library of regulations and obligations designed to help delivery efficiency and ultimately lead to better client outcomes. This reference library is critical for delivering regulatory assessments, control testing, and control framework implementation engagements. This structure allows quick and efficient access to information which can be downloaded to support client engagements.</p>
                <p>Whether you’re tracking the latest developments in the Market Abuse Regulation (MAR) to combat insider trading and market manipulation, understanding the reporting and transparency obligations under REMIT (Regulation on Wholesale Energy Market Integrity and Transparency), or ensuring alignment with the risk mitigation frameworks of EMIR (European Market Infrastructure Regulation), the Commodities regulatory library offers clarity in a dynamic environment.</p>
                <p>While we are committed to maintaining the high-quality information in our library, always consult with the relevant specialists to ensure you are using the most up-to-date, comprehensive, and relevant reference materials. Regulatory information should never be relied upon without specialist oversight.</p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-container fluid style="background-color: #1E49E2; color: white; margin: 0px;">
    <b-row>
      <b-col>
        <b-container fluid style="padding-top: 100px; padding-bottom: 80px">
          <b-row>
            <b-col>
              <b-container>
              <b-row>
                <b-col lg="4">
                  <img src="/img/controls-utopia.jpg" style="width: 100%; height: auto;" />
                </b-col>
                <b-col lg="8" sm="12">
                  <h2 class="mb-4" style="font-size: 20px; font-weight: bold;">Commodities Risk & Regulation</h2>
                  <div>
                    <p>Commodities are the foundation of modern life—fueling our homes, powering industries, and forming the raw materials for countless everyday products. Ensuring the fairness, transparency, and soundness of wholesale commodity markets is not just about compliance; it’s about fostering good outcomes for consumers and sustaining trust in the markets that drive our global economy.</p>
                    <p>The Commodities regulatory library is your essential resource for navigating the complex and evolving regulatory landscape of the regulated commodity trading sector. Designed to empower businesses and professionals, this comprehensive tool provides an inventory of key regulatory initiatives and obligations that impact commodity trading, helping you stay ahead of compliance requirements.</p>
                    <p>By consolidating critical regulatory updates, insights, and obligations into a single, easy-to-use platform, the Commodities Regulatory Library helps you navigate the complexities of compliance, mitigate risks, and contribute to fair and well-functioning commodity markets.</p>
                  </div>
                </b-col>
              </b-row>
              <b-row style="margin-top: 100px;">
                <b-col class="text-center" lg="3" sm="12">
                  <div>
                    <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/duncanmichie.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
                  </div>
                  <p class="mb-0"><strong>Duncan Michie</strong></p>
                  <p class="mb-0"><small>Director, Economics & Utilities Regulation</small></p>
                  <p class="mb-5"><small>KPMG in the UK</small></p>
                </b-col>
                <b-col class="text-center" lg="3" sm="12">
                  <div>
                    <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/rajarshiguha.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
                  </div>
                  <p class="mb-0"><strong>Rajarshi Guha</strong></p>
                  <p class="mb-0"><small>Senior Manager, Commodities Trading Risk & Regulation</small></p>
                  <p><small>KPMG in the UK</small></p>
                </b-col>
                <b-col class="text-center" lg="3" sm="12">
                  <div>
                    <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/hajirakhushnood.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
                  </div>
                  <p class="mb-0"><strong>Hajira Khushnood</strong></p>
                  <p class="mb-0"><small>Manager, Commodities Trading Risk & Regulation</small></p>
                  <p><small>KPMG in the UK</small></p>
                </b-col>
                <b-col class="text-center" lg="3" sm="12">
                  <div>
                    <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/narindersahota.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
                  </div>
                  <p class="mb-0"><strong>Narinder Sahota</strong></p>
                  <p class="mb-0"><small>Manager, Commodities Trading Risk & Regulation</small></p>
                  <p><small>KPMG in the UK</small></p>
                </b-col>
              </b-row>
            </b-container>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="mt-4" style="padding-top: 100px;">
    <b-row v-if="aipost">
      <b-col>
        <h2 class="mb-2" style="font-size: 20px; font-weight: bold;">AI on Commodities</h2>
        <p class="mb-3">Summary of recent developments generated automatically by Gen AI - may contain mistakes</p>
      </b-col>
    </b-row>
    <b-row class="shadow px-3 py-4 bg-white mb-5" v-if="aipost">
      <b-col>
        <h4 class="mb-3" style="font-size: 18px; font-weight: bold;">{{ aipost.name }}</h4>
        <div class="mb-3">{{ aipost.created_at.slice(0,10) }}</div>
        <div>
          <markdown :content="aipost.body" />
        </div>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="mt-4">
    <b-row>
      <b-col lg="8" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">KPMG Insights</h2>
        <p>Recent KPMG publications</p>
        <div v-if="!loadingArticles && articles.length > 0">
          <b-container class="shadow p-5" style="background-color: #1E49E2; color: white;">
            <b-row>
              <b-col lg="8" sm="12">
                <div><b-link :to="{ name: 'ControlsInsight', params: {id: article.id }}" style="color: white;"><strong>{{article.title}}</strong></b-link></div>
                <div class="mb-2"><small>{{moment(article.approved_at).format('YYYY-MM-DD')}} by {{ article.approved_by }}</small></div>
                <div>{{ article.summary }}</div>
              </b-col>
              <b-col class="text-center" lg="4" sm="12">
                <div>
                  <div :style="article.style"></div>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div v-for="item in articles" :key="'articles'+item.id" class="my-4">
            <b-container class="shadow p-3 bg-white">
              <b-row>
                <b-col>
                  <div><b-link :to="{ name: 'ControlsInsight', params: {id: item.id }}"><strong>{{item.title}}</strong></b-link></div>
                  <div class="mb-2"><small>{{moment(item.approved_at).format('YYYY-MM-DD')}} by {{ item.approved_by }}</small></div>
                  <div>{{ item.summary }}</div>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div class="mt-4 mb-2"><b-link :to="{ name: 'ControlsInsights' }"><strong>More Insights</strong></b-link></div>
        </div>
        <div class="my-3" v-if="loadingArticles">
          <b-spinner label="loading insights..." />
          loading insights...
        </div>
        <h2 class="mt-5" style="font-size: 20px; font-weight: bold;">Contact us</h2>
        <p>Provide feedback, request features or support</p>
        <b-container class="shadow p-3 bg-white">
          <b-row>
            <b-col>
              <contact-form subject="KPMG Commodities feedback" />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="4" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">News</h2>
        <p>Recent industry updates</p>
        <div class="shadow py-4 px-3 bg-white" v-if="!loading">
          <div class="my-3" v-for="(item, index) in news" :key="'news-' + index">
            <div>
              <router-link :to="{ name: 'CommoditiesNewsItem', params: { id: item.id }}">
                <div><strong>{{item.name}}</strong></div>
                <div><small>{{ item.date.slice(0,10) }} - {{ item.countryName }} - {{ item.publisher }}</small></div>
              </router-link>
            </div>
          </div>
          <div>
            <router-link class="mt-4 mb-2" :to="{ name: 'CommoditiesNews', params: {id: 154 }}"><strong>More News</strong></router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import moment from 'moment'

import contactForm from '@/components/ContactForm.vue'
import Markdown from '@/components/Markdown.vue'
import { BIconBinoculars, BIconFolder, BIconGrid3x3, BIconNewspaper } from 'bootstrap-vue'

function getUsernameFromEmail (email) {
  const match = email.match(/^[^@]+/)
  return match ? match[0].replace(/\./g, '') : null
}

export default {
  name: 'CommoditiesHome',
  components: {
    BIconBinoculars,
    BIconFolder,
    BIconGrid3x3,
    BIconNewspaper,
    contactForm,
    Markdown
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      aipost: null,
      article: null,
      articles: [],
      loading: true,
      loadingArticles: true,
      moment: moment,
      news: []
    }
  },
  created: async function () {
    this.$store.commit('setBrand', 'Commodities')
    document.title = "KPMG Commodities"
    const aiposts = await this.$Amplify.API.post('cosmos', `/aiposts/topics/limit/1/offset/0`, { body: { topics: [176, 143] } })
    if (aiposts.length > 0) {
      this.aipost = aiposts[0]
    }
    this.news = await this.$Amplify.API.get('cosmos', '/news/topicgroup/1/standardformat/limit/10')
    this.loading = false
    const articles = await await this.$Amplify.API.post('cosmos', `/articles/topics/limit/5`, { body: { topics: [176, 143] } })
    articles.forEach(x => {
      const img = getUsernameFromEmail(x.approved_by)
      x.img = img
      x.style = `margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/${img}.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;`
    })
    const article = articles.shift()
    this.article = article
    this.articles = articles
    this.loadingArticles = false
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.carousel-caption {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%; /* Ensure the div takes full height of the slide */
  padding-top: 1rem; /* Optional: add some padding to the top */
}

.carousel-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%; /* Ensure the div takes full height of the slide */
  padding-top: 1rem; /* Optional: add some padding to the top */
}

.home-icon {
  color: white;
  height: 90px;
  width: 90px;
  margin: 20px;
}
.link-card {
  font-size: 16px;
  font-weight: bold;
}

.link-card div:hover {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

.link-cards a:hover {
    text-decoration: none;
}
</style>
